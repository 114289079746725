import React, { useEffect, useRef } from 'react'

const UnneLogoSvg = (props) => {
  const svgRef = useRef(null);

  useEffect(() => {
    const animateFirst = () => {
      const svgElement = svgRef.current;
      if (svgElement) {
        const firstAnimate = svgElement.querySelector('animate');
        if (firstAnimate) {
          firstAnimate.beginElement();
        }
      }
    };

    const intervalId = setInterval(animateFirst, 10000); // Repetir cada 15 segundos el primer animate

    return () => {
      clearInterval(intervalId);
    };
  }, []);//<svg ref={svgRef} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 120" {...props} width={220}>


  return (
    <svg ref={svgRef} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 120" {...props} width={120}>
    <g transform="matrix(.02762 0 0 -.02762 -.964 120.742)">
      <ellipse
        cx={548.826}
        cy={-3607.503}
        rx={382.644}
        ry={46.553}
        style={{
          // fill: "#d8d8d8",
          fill: "#d1d1d1",
          stroke: "#000",
        }}
        transform="scale(1 -1)"
      />
      <path
        fill="#E85512"
        d="M551.963 3990.953c-201.487-1.26-378.451-154.605-386.452-371.832-8.508-230.997 188.355-394.91 378.523-395.509 233.853-.736 385.618 186.329 387.221 379.771 1.803 217.499-181.229 388.809-379.292 387.57Z"
        opacity="100%"
      >
        <animate
          fill="freeze"
          attributeName="d"
          begin="1.91s"
          dur="0.19s"
          keyTimes="0; 0.5; 1"
          values="M 551.963 3990.953 C 350.476 3989.693 173.512 3836.348 165.511 3619.121 C 157.003 3388.124 353.866 3224.211 544.034 3223.612 C 777.887 3222.876 929.652 3409.941 931.255 3603.383 C 933.058 3820.882 750.026 3992.192 551.963 3990.953 Z;M 546.776 3655.105 C 388.491 3652.117 180.235 3637.423 165.511 3610.036 C 161.944 3574.391 349.976 3561.223 540.144 3560.624 C 773.997 3559.888 920.154 3578.694 931.255 3603.383 C 935.632 3644.736 672.756 3656.144 546.776 3655.105 Z;M 551.963 3990.953 C 350.476 3989.693 173.512 3836.348 165.511 3619.121 C 157.003 3388.124 353.866 3224.211 544.034 3223.612 C 777.887 3222.876 929.652 3409.941 931.255 3603.383 C 933.058 3820.882 750.026 3992.192 551.963 3990.953 Z"
        />
      </path>
      <path
        fill="#353843"
        d="M377 2790c-66-11-166-60-216-105-24-22-56-68-79-113l-37-76v-341c0-357 3-396 51-586 99-398 341-796 633-1041 293-245 615-395 1001-465 119-22 474-24 595-5 348 57 601 163 875 366 342 253 639 691 766 1126 58 199 66 264 71 590 5 293 4 308-17 370-44 134-132 222-262 266-147 48-346-18-425-142-78-121-77-115-84-464-4-243-10-333-23-393-19-88-68-231-99-292-78-155-252-352-400-453-208-143-462-211-742-199-139 6-232 22-353 63-331 110-641 427-746 762-46 148-56 234-56 496 0 257-8 328-44 410-50 110-171 201-300 225-33 6-60 10-60 10-1-1-23-5-49-9Z"
      />
      <path
        fill="#FABA16"
        d="M6615 4039c-416-24-769-160-1096-422-381-307-664-786-761-1292-21-106-22-143-25-1000-3-646-1-906 7-950 16-80 73-188 124-231 108-93 253-125 376-84 125 41 196 105 253 227l22 48 5 935c6 1031 4 997 72 1188 64 182 150 316 293 457 234 231 499 335 851 335 348 0 617-111 859-355 201-202 316-441 345-715 5-55 10-454 10-950V375l24-67c33-94 82-156 158-201 136-81 252-88 383-23 112 56 188 155 216 284 7 34 9 341 6 963-4 780-6 927-20 1005-135 772-636 1364-1357 1605-148 49-337 87-470 94-58 3-116 7-130 9-14 1-79-1-145-5z"
      />
      <path
        fill="#E85512"
        d="M11470 4043c-109-4-249-23-370-50-495-109-952-451-1239-927-104-172-203-399-250-576-57-216-56-185-56-1206V344l37-76c45-91 103-147 196-191 60-29 77-32 157-32s97 3 156 31c116 55 181 131 220 255 18 58 19 107 19 937 0 951 2 981 56 1152 61 194 148 337 298 486 167 166 323 256 545 312 97 24 122 27 306 27 228 0 292-11 460-76 306-119 589-416 695-730 56-163 53-122 59-1134l6-940 23-57c53-130 136-210 260-248 84-26 152-25 233 4 94 33 154 78 200 149 72 112 69 58 69 1075 0 991-1 1004-56 1232-85 346-294 713-541 951-399 384-939 593-1483 572z"
      />
      <path
        fill="#353843"
        d="M15940 4034c-14-2-59-9-100-15-300-43-635-183-897-376-494-362-803-975-803-1594 0-446 123-822 380-1164 303-402 779-700 1297-812l128-28h1890l77 37c94 45 146 95 190 185 31 63 33 75 33 168s-2 105-33 168c-44 89-92 136-188 183l-78 39-945 6c-838 5-954 7-1016 22-253 61-423 155-600 332-162 162-251 318-307 533-36 140-48 351-29 495 34 251 140 470 319 659 191 200 406 317 669 363 71 13 229 15 952 15 582 0 887 4 927 11 77 14 184 71 230 121 20 22 50 70 68 107 28 60 31 75 31 162 0 90-2 99-37 171-45 89-94 138-187 182l-66 31-940 1c-517 1-951 0-965-2z"
      />
      <path
        fill="#FABA16"
        d="M16548 2444c-132-31-217-98-274-211-37-74-39-83-39-173 0-86 3-101 31-161 18-37 48-85 68-107 43-47 150-106 224-121 68-14 1087-15 1161-1 129 24 231 101 288 218 36 72 38 81 38 171 0 87-3 102-31 161-17 36-48 84-68 107-46 52-158 110-241 123-34 6-286 10-580 9-407 0-532-4-577-15z"
      />
    </g>
    <path
      fill="#fff"
      d="M51.255 63.586c-.156.12-17.58-9.424-64.465-36.423 25.03 177.919 156.337 79.709 122.045 7.456-50.36 26.04-56.97 28.018-57.58 28.967Z"
      opacity="100%"
    >
      <animate
        fill="freeze"
        attributeName="d"
        begin="0.15s"
        dur="1.59s"
        keyTimes="0; 0.20682; 0.44881; 0.63303; 0.80312; 0.90565; 1"
        values="M 51.255 63.586 C 51.099 63.706 33.675 54.162 -13.21 27.163 C 11.82 205.082 143.127 106.872 108.835 34.619 C 58.475 60.659 51.865 62.637 51.255 63.586 Z;M 51.255 63.586 C 51.099 63.706 22.366 98.413 -24.519 71.414 C 0.511 249.333 143.127 106.872 108.835 34.619 C 58.475 60.659 51.865 62.637 51.255 63.586 Z;M 51.255 63.586 C 51.099 63.706 54.325 156.431 7.44 129.432 C 57.792 169.682 143.127 106.872 108.835 34.619 C 58.475 60.659 51.865 62.637 51.255 63.586 Z;M 51.255 63.586 C 51.099 63.706 98.083 108.002 87.091 124.024 C 101.797 113.386 143.127 106.872 108.835 34.619 C 58.475 60.659 51.865 62.637 51.255 63.586 Z;M 51.255 63.586 C 51.099 63.706 120.7 68.667 115.854 86.902 C 122.693 70.61 117.56 50.575 108.835 34.619 C 58.475 60.659 51.865 62.637 51.255 63.586 Z;M 51.255 63.586 C 51.099 63.706 123.404 41.379 118.558 59.614 C 125.397 43.322 117.56 50.575 108.835 34.619 C 58.475 60.659 51.865 62.637 51.255 63.586 Z;M 51.255 63.586 C 51.099 63.706 121.683 25.891 116.837 44.126 C 123.676 27.834 117.56 50.575 108.835 34.619 C 58.475 60.659 51.865 62.637 51.255 63.586 Z"
      />
    </path>
    <path
      fill="#FABA16"
      d="M14.287 10.499c-5.564.035-10.452 4.27-10.673 10.269-.235 6.38 5.202 10.907 10.454 10.924 6.459.02 10.65-5.146 10.695-10.489.05-6.007-5.005-10.738-10.476-10.704Z"
      opacity="100%"
    >
      <animateMotion
        fill="freeze"
        calcMode="linear"
        dur="1.83s"
        path="M -0.026 0.366 C -1.206 13.546 -2.65 20.398 -3.659 33.754 C -2.939 111.823 85.951 97.835 85.324 39.867 C 85.172 25.782 86.625 14.304 85.541 0"
      />
    </path>
  </svg>
  )
}

export default UnneLogoSvg
